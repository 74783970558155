<script lang="ts" setup>
import Parallaxy from '@lucien144/vue3-parallaxy'
import Link1 from '@/components/Button/Link1.vue'
import Link2 from '@/components/Button/Link2.vue'
import Title1 from '@/components/Title/Title1.vue'
import { type HomeSectionList } from '@/utils/types'
import { usePageStore } from '@/stores'
const pageStore = usePageStore()
const blockData = computed(() => pageStore.homeSection4)

const props = defineProps({
  bgColor: {
    type: String,
    default: ''
  }
})

interface Service {
  title: string
  text: string
  link: string
  linkTitle: string
}

const lists: Ref<HomeSectionList[]> = ref([
  {
    pic: blockData.value.image_lg,
    speed: 90,
    direction: 'normal'
  },
  {
    pic: blockData.value.image_sm,
    speed: 80,
    direction: 'normal'
  }
])

const serviceLists: Ref<Service[]> = ref([
  {
    title: '產品保固註冊',
    text: '完成您購買產品保固註冊，以保障原廠認證售後服務',
    link: '/services/warranty/form',
    linkTitle: '立即註冊'
  },
  {
    title: '維修服務申請',
    text: '了解恆隆行維修服務流程，並且線上完成申請',
    link: '/services/repair/form',
    linkTitle: '立即申請'
  },
  {
    title: '服務進度查詢',
    text: '顯示維修服務處理進度，以及保固、贈品審核進度',
    link: '/member/service',
    linkTitle: '我要查詢'
  }
])
</script>

<template>
  <section class="section-box">
    <div class="wrap relative">
      <div class="section-number">
        <Parallaxy :breakpoints="{ 991: { axis: 'y', speed: 120 } }" :speed="0" direction="opposite"
          :animation="(delta: number) => `transform: translate3d(0, ${delta}px, 0);`">
          <SectionNumber no="4" :class="{ brown: props.bgColor === '#f2f2f2' }" />
        </Parallaxy>
      </div>
      <div v-for="(item, index) in lists" :key="index" :class="`lists-${index + 1}`">
        <Parallaxy :breakpoints="{ 991: { axis: 'y', speed: item.speed } }" :speed="0" :direction="item.direction"
          :animation="(delta: number) => `transform: translate3d(0, ${delta}px, 0);`">
          <div class="lists__pic">
            <Nimg :src="item.pic?.link" :alt="item.pic?.alt" :title="item.pic?.title" />
          </div>
        </Parallaxy>
      </div>
      <div class="section-title">
        <Title1 class="section-title__inner">
          <template v-slot:en>Services</template>
          <template v-slot:tw> 會員服務 </template>
          <template v-slot:link>
            <Link2 :to="blockData.url || '#'" class="circle" linkTitle="完整服務" alt="完整服務"></Link2>
          </template>
        </Title1>
      </div>
      <div class="service-box">
        <ul class="service-lists">
          <li v-for="(item, index) in serviceLists" :key="index">
            <div class="service-lists__item">
              <h3 class="service-lists__title">{{ item.title }}</h3>
              <div class="service-lists__text" v-if="item.text">{{ item.text }}</div>
              <Link1 :to="item.link" class="service-lists__link" color="gray-3">{{ item.linkTitle }}</Link1>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.section-box {
  .wrap {
    @include grid;

    @include max-media(990) {
      max-width: toRem(520);
      @include grid(4, 24);
    }
  }
}

.section-number {
  width: toRem(118);
  height: toRem(113);
  order: 2;
  grid-column: 5 / span 2;

  @include max-media(990) {
    order: 1;
    grid-column: 1 / span 1;
    margin-top: toRem(-50);
    width: 100%;
  }
}

.section-title {
  @include min-media(991) {
    position: absolute;
    left: 0;
    top: toRem(150);
    width: 100%;
    padding-left: toRem(24);
    padding-right: toRem(24);
    @include grid;
  }

  @include max-media(990) {
    @include grid(4, 24);
    grid-column: 1 / span 4;
    order: 4;
    padding-left: 0;
    padding-right: 0;
  }

  &__inner {
    grid-column: 8 / span 3;

    @include max-media(990) {
      grid-column: 2 / span 3;
    }
  }
}

.lists {
  &-1 {
    order: 1;
    grid-column: 1 / span 4;

    @include max-media(990) {
      order: 2;
      grid-column: 2 / span 2;
      grid-row: none;
    }
  }

  &-2 {
    order: 3;
    grid-column: 11 / span 2;

    @include max-media(990) {
      grid-column: 4 / span 1;
      margin-left: toRem(-24);
    }
  }

  &__pic {
    img {
      display: block;
      width: 100%;
    }
  }
}

.service-box {
  order: 4;
  grid-column: 1 / span 12;
  padding-top: toRem(50);
  padding-bottom: toRem(150);

  @include max-media(1280) {
    padding-top: toRem(100);
  }

  @include max-media(990) {
    order: 5;
    grid-column: 1 / span 4;
  }
}

.service-lists {
  @include grid;

  @include max-media(990) {
    @include grid(4, 24);
  }

  li {
    &:nth-child(1) {
      grid-column: 3 / span 2;

      @include max-media(990) {
        grid-column: 2 / span 3;
      }
    }

    &:nth-child(2) {
      grid-column: 6 / span 2;

      @include max-media(990) {
        grid-column: 2 / span 3;
      }
    }

    &:nth-child(3) {
      grid-column: 9 / span 2;

      @include max-media(990) {
        grid-column: 2 / span 3;
      }
    }

    @include max-media(990) {
      margin-bottom: toRem(20);
    }
  }

  &__title {
    margin-bottom: toRem(8);
    font-size: toRem(28);
    // font-size: 1.94vw;
    font-weight: 500;
  }

  &__text {
    min-height: toRem(76);
    margin-bottom: toRem(20);
  }
}
</style>
